<template>
    <div class="columns">
        <div class="column" :class="col.settings.class" v-for="(col, index) in comp.columns" :key="index">
            <page-component v-for="(comp, index) in col.children" :comp="comp" :key="index"/>
        </div>
    </div>
</template>

<script>
  import PageComponent from '@/components/PageComponent'

  export default {
    name: 'Comp_grid',
    props: {
      comp: Object,
    },

    components: {
      PageComponent
    }
  }
</script>
